<template>
  <div class="hc_detail" style="width:880px;margin-top: 0">
    <div class="cool_detail_box" style="padding: 20px">
      <div class="cd_hedader">
        <h3 class="cd_title">{{coolCont.name}}</h3>
        <p class="cd_time">
          <span class="el-icon-time">{{coolMoment(coolCont.publishTime)}}</span>
        </p>
        <h2 class="cd_tagline" v-if="tagList.length!=0">专属标签</h2>
        <div class="cd_tagbox" v-if="tagList.length!=0">
          <div class="cd_cover">
            <img :src="setUrl(coolCont.showImg)" alt="">
          </div>
          <div class="cd_rate_box">
            <div class="cd_rate_item" v-for="item of tagList" :key="item.id">
              <span class="cool_rname">{{item.tag}}:</span>
              <el-rate 
                v-model="item.num"
                disabled
                void-icon-class="iconfont icon-pethome-xingxing"
                :icon-classes="['iconfont icon-pethome-xingxing','iconfont icon-pethome-xingxing','iconfont icon-pethome-xingxing']"
                void-color="#ccc"
                :colors="['#049aee','#049aee','#049aee']"
                class="pet_rate"
              ></el-rate>
            </div>
          </div>
        </div>
        <div class="cd_banner">
          <h2>图片鉴赏: <span>点击观看原始高清图</span></h2>
          <el-carousel :interval="4000" type="card" height="300px" v-if="coolPhotos.length!=0">
            <el-carousel-item v-for="(item,index) of coolPhotos" :key="index">
              <div class="pet_photos_item" @click="showHimg(index)">
                <img :src="setUrl(item)">
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <h2 class="cd_tagline">正文赏析</h2>
        <div class="cd_con" v-html="coolContent">
          {{coolContent}}
        </div>
      </div>
    </div>
    <el-image-viewer
      v-if="isShow"
      :initial-index="index"
      :on-close="closeViewer"
      :url-list="srcList"
    />
  </div>
</template>
<script>
import moment from 'moment';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';

export default {
  name: 'coolDetail',
  components: { ElImageViewer },
  props: {
		coolCont: {
			type: Object,
			required: true,
		},
	},
  data(){
    return{
      index: 0,
      isShow: false,
      srcList: [],
      adlist: [],
      coolPhotos: [],
    }
  },
  computed:{
    setUrl() {
			return url=>{
				if(url.indexOf("http")==0){
					return url;
				}else{
					return '/officialWebsiteMgr/resource/file/getImage?fileName=' + url
				}
			}
    },
    tagList(){
      if(this.coolCont.showTag && this.coolCont.showTag!=""){
        return JSON.parse(this.coolCont.showTag)
      }else{
        return [];
      }
    },
    coolMoment(){
      return time=>{
        return moment(time).format('YY-MM-DD HH:mm:ss')
      }
    },
    coolContent(){
      if(this.coolCont.content){
        let _cont = JSON.parse(this.coolCont.content);
        let _html="";
        _cont.map(item=>{
          if(item.type=="words"){
            _html+="<p>"+item.cont+"</p>";
          }
          if(item.type=="img"){
            _html+="<p class='conimg'><img src='/officialWebsiteMgr/resource/file/getImage?fileName="+item.cont+"'></p>";
          }
        })
        return _html;
      }
    },
  },
  created(){
    this.init();
  },
  watch:{
    coolCont(){
      this.init();
    },
  },
	methods: {
    showHimg(ind){
      this.index = ind;
      this.isShow = true;
    },
    closeViewer(){
      this.isShow = false;
    },
    init(){
      if(_.isEmpty(this.coolCont)){
        return false;
      }else{
        console.log(this.coolCont)
        if(this.coolCont.bannerImg!=""){
          this.coolPhotos = this.coolCont.bannerImg.split(",");
          if(this.coolPhotos.length!=0){
            let srcAry = this.coolPhotos.map(item=>{
              return this.setUrl(item)
            })
            this.srcList = srcAry
          }
        }
      }
    }
	},
}
</script>
<style lang="scss">
  @import "~@/assets/css/cool.scss";
</style>
